import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Portfolio = () => {
  return (
    <section
      className="portfolio-section"
      style={{
        margin: `0 auto`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
      }}
    >
      <div className="container">
        <header className="text-center">
          <h1 className="section-title">Portfolio</h1>
          <p>Some of my best works</p>
        </header>
        <div className="row align-items-center">
          <div class="col-lg-6">
            <a
              href="https://www.letsmovecity.com"
              target="_blank"
              class="card move"
              rel="noopener noreferrer nofollow"
            >
              <div>
                <StaticImage
                  style={{ width: "940px" }}
                  src="../images/lets-move-city.png"
                  alt=""
                />
                <h4 class="pre-title">Ride Sharing App
                  <div className="icon" style={{ 'width': '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#e0e0e0" d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg>
                  </div>
                </h4>
                <h3>Let's Move City </h3>
                <p>HTML, CSS, Gatsby JS, getform,</p>
              </div>
            </a>
          </div>
          <div class="col-lg-6">
            <a
              href="https://jovial-kirch-6218c8.netlify.app/"
              target="_blank"
              class="card artsnpaper"
              rel="noopener noreferrer nofollow"
            >
              <StaticImage
                style={{ width: "940px" }}
                src="../images/artsnpaper.png"
                alt=""
              />
              <h4 class="pre-title">
                Design Studio
                <div className="icon" style={{ 'width': '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#e0e0e0" d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg>
                </div>
              </h4>
              <h3>ArtsnPaper</h3>
              <p>HTML, CSS, WOW JS, slick slider</p>
            </a>
          </div>
        </div>
        <div className="row align-items-center">
          <div class="col-lg-6">
            <a
              href="https://contacthealth.com/"
              target="_blank"
              class="card ch"
              rel="noopener noreferrer nofollow"
            >
              <div>
                <StaticImage
                  style={{ width: "940px" }}
                  src="../images/contact-health.png"
                  alt=""
                />
                <h4 class="pre-title">
                  Health Care Services
                  <div className="icon" style={{ 'width': '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#e0e0e0" d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg>
                  </div>
                </h4>
                <h3>Contact Health</h3>
                <p>HTML, CSS, WordPress, CSS Animations</p>
              </div>
            </a>
          </div>
          <div class="col-lg-6">
            <a
              href="https://www.techneteam.com/"
              target="_blank"
              class="card techneteam"
              rel="noopener noreferrer nofollow"
            >
              <StaticImage
                style={{ width: "940px" }}
                src="../images/techneteam.png"
                alt=""
              />
              <h4 class="pre-title">
                HRM Software
                <div className="icon" style={{ 'width': '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#e0e0e0" d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg>
                </div>
              </h4>
              <h3>Techne Team</h3>
              <p>React JS, Redux</p>
            </a>
          </div>
        </div>
        <div className="row align-items-center">
          <div class="col-lg-6">
            <a
              href="https://www.vatermelon.com/"
              target="_blank"
              class="card vm"
              rel="noopener noreferrer nofollow"
            >
              <div>
                <StaticImage
                  style={{ width: "940px" }}
                  src="../images/vatermelon.png"
                  alt=""
                />
                <h4 class="pre-title">
                  NFT Landing Page
                  <div className="icon" style={{ 'width': '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#e0e0e0" d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg>
                  </div>  
                </h4>
                <h3>Vater Melon</h3>
                <p>HTML, CSS, JS</p>
              </div>
            </a>
          </div>
          <div class="col-lg-6">
            <a
              href="https://www.udefyfitness.com/"
              target="_blank"
              class="card uf"
              rel="noopener noreferrer nofollow"
            >
              <StaticImage
                style={{ width: "940px" }}
                src="../images/udefyfitness.png"
                alt=""

              />
              <h4 class="pre-title">Fitness Trainer <div className="icon" style={{ 'width': '15px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="#e0e0e0" d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" /></svg>
                </div></h4>
              <h3>Udefy Fitness</h3>
              <p>HTML, CSS, WordPress</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
