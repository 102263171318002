import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/style.scss"
import "bootstrap-4-grid/css/grid.css"
import Portfolio from "../components/portfolio"

const IndexPage = () => (
  <Layout>
    <Seo title="Faiz Ahmed | UX / UI &amp; Front-end Developer" />
    <Portfolio />
  </Layout>
)

export default IndexPage
